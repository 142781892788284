body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style: none;
}

.MuiStepConnector-line.MuiStepConnector-lineVertical {
  min-height: 10px;
}

a:-webkit-any-link {
  text-decoration: none;
}

.bounce-7 {
  animation-name: bounce-7;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
}
@keyframes bounce-7 {
  0% {
    transform: scale(1, 1) translateY(0);
  }

  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }

  30% {
    transform: scale(0.9, 1.1) translateY(-50px);
  }

  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }

  57% {
    transform: scale(1, 1) translateY(-7px);
  }

  64% {
    transform: scale(1, 1) translateY(0);
  }

  100% {
    transform: scale(1, 1) translateY(0);
  }
}

@keyframes cascade_vertical {
  0% {
    top: 90%;
    left: 0%;
  }

  33% {
    top: 90%;
    left: 1%;
  }

  66% {
    top: 90%;
    left: 1%;
  }

  100% {
    top: 90%;
    left: 0%;
  }
}

@keyframes cascade_horizontal {
  0% {
    top: 89%;
    left: 0%;
  }

  33% {
    top: 89%;
    left: 0%;
  }

  66% {
    top: 90%;
    left: 0%;
  }

  100% {
    top: 90%;
    left: 0%;
  }
}

@keyframes cascade_diagonal {
  0% {
    top: 89%;
    left: 0%;
  }

  100% {
    top: 90%;
    left: 1%;
  }
}

@keyframes falling_water {
  0% {
    top: 0%;
    height: 30px;
  }

  100% {
    top: 90%;
  }
}

#commento-footer {
  display: none;
}

#commento-main-area {
  position: relative;
  top: -130px;
  z-index: 10;
}

#commento-login-box {
  top: -87px;
}

#json-input-paper div[name="body"] {
  min-height: 300px;
  padding: 10px 20px;
  border: 1px solid #dddddd;
  border-radius: 4px;
}

.MuiPopover-root .MuiPopover-paper {
  max-width: 350px;
}

.text-ellipsis-1 {
  display: -webkit-box !important;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.text-ellipsis-2 {
  display: -webkit-box !important;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-ellipsis-3 {
  display: -webkit-box !important;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.text-ellipsis-4 {
  display: -webkit-box !important;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spin {
  -webkit-animation: rotating 1.5s linear infinite;
  -moz-animation: rotating 1.5s linear infinite;
  -ms-animation: rotating 1.5s linear infinite;
  -o-animation: rotating 1.5s linear infinite;
  animation: rotating 1.5s linear infinite;
}

.lds-roller {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  transform-origin: 40px 40px;
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.lds-roller div:after {
  position: absolute;
  display: block;
  width: 7px;
  height: 7px;
  margin: -4px 0 0 -4px;
  border-radius: 50%;
  content: " ";
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fade-enter {
  z-index: 1;
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}
